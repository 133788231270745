//import Eazypg from "./images/eazypg.JPG";
import Felix from "./images/felix1.PNG";
import TP from "./images/techprescient.png";
import CT from "./images/CT.png";

export const experience = [
  {
    title: "Corptec Technology Partners",
    image: CT,
    description:
      "Corptec collaborates with businesses to use technology to manage and transform their operations. Our mission is to unlock human potential via technology for an inclusive and sustainable future, serves as its compass each day.",
    tools: ["Front End Developer", "Currently Working"],

    link: "https://corptec.com.au/",
  },
  {
    title: "Tech Prescient",
    image: TP,
    description:
      "Tech Prescient is a focused software product development and technology services company, deeply passionate about customer success.",
    tools: ["Front End Developer"],

    link: "https://www.techprescient.com/",
  },
  {
    title: "Felix Healthcare AI",
    image: Felix,
    description:
      "Felix Healthcare offers its patented Artificial Intelligence Healthcare engine that incorporates machine learning and deep learning technologies to help healthcare organizations transform their entire process",
    tools: ["Front End Developer"],

    link: "https://www.felixhealthcare.ai/",
  },

  // {
  //   title: "Eazy PG",
  //   image: Eazypg,
  //   description:
  //     "Making renting a happy choice from being a forced one for both owners & tenants India runs on renting... Hostels, PGs, Flats, Homestays, Shops, Godowns, Warehouses and Offices,",
  //   tools: ["Full Stack Developer"],
  //   //github: "https://github.com/anuragmy/mern",
  //   link: "https://eazypg.in/",
  // },
];

export default experience;
