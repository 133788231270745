import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import animate from "lottie-web";

const Hero = () => {
  const container = useRef(null);
  React.useMemo(() => container, [container]);

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("./astronaout.json"),
    });
    animate.setSpeed(0.5);


  }, []);

  // analytics


  return (
    <section
      className="my-16 px-5 md:my-32 flex flex-col flex-wrap space-y-16 md:space-y-0 patterns"
      style={{ marginTop: 10 }}
    >
      <div className="ml-auto w-72 md:w-96 lg:w-2/5">
        <div
          className="container"
          ref={container}
          style={{ minHeight: 300 }}
        ></div>
      </div>
      <div className="max-w-md mb-auto space-y-5">
        <h1 className="text-5xl font-bold md:text-7xl">Hello. I’m Anurag</h1>
        <p className="tracking-wide leading-relaxed">
          I m a web developer, I have programming experience in JavaScript with
          relevant projects. I like to read a lot about the current technology
          market and changes in the web development field.
        </p>
      </div>
    </section>
  );
};

export default Hero;
