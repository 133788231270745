import Crown from "./images/cch.png";
import Contact from "./images/cl.png";
import Youtube from "./images/yc.png";
import Descion from "./images/descion.png";
import Notes from "./images/notes.png";
import News from "./images/news.png";
import Euler from "./images/euler.png";

export const clientProjects = [
  {
    title: "Euler Motors",
    image: Euler,
    description:
      "Road-ready, sustainable last-mile electric logistic solutions",
    tools: ["React", "Redux", "AdobeXD", "Strapi"],
    link: "https://eulermotors.com/",
  },
];

export const projects = [
  {
    title: "Crown Clothing",
    image: Crown,
    description:
      "Full featured ecommerce store built with MERN stack and Razorpay payments API. Admin page to manage customers, products & orders.",
    tools: ["React", "Redux", "MongoDB", "JWT", "Express"],
    github: "https://github.com/anuragmy/mern",
    //link: "https://crownclothing.independentvariable.online",
  },
  {
    title: "Contact Manager App",
    image: Contact,
    description:
      "A Contact Manager Single Page App to add, update and delete contacts using React and Redux",
    tools: ["React", "Redux", "SASS"],
    github: "https://github.com/anuragmy/contact-manager",
    link: "https://contact-manager.independentvariable.online",
  },
  {
    title: "News App",
    image: News,
    description:
      "A react web app which displays news , by search and category using inshorts and gnews api",
    tools: ["ES6", "React", "Redux", "firebase"],
    github: "https://github.com/anuragmy/news-api",
    link: "https://newsappio.netlify.app",
  },
  {
    title: "Youtube Clone",
    image: Youtube,
    description:
      "A react web app which fetches video content and displays using google api",
    tools: ["ES6", "React", "Redux"],
    github: "https://github.com/anuragmy/react-youtube",
    link: "https://youtube.independentvariable.online",
  },
  {
    title: "Descion App",
    image: Descion,
    description: "A discion app made with React and bundled with webpack",
    tools: ["ES6", "React", "Redux", "SASS"],
    github: "https://github.com/anuragmy/indiscion",
    //link: "https://indecision.independentvariable.online",
  },
  {
    title: "Notes App",
    image: Notes,
    description:
      "A Notes Manager, Single Page App to add, update and delete notes using React and Redux",
    tools: ["React", "Redux", "Semantic UI"],
    github: "https://github.com/anuragmy/react-online-notes",
    //link: "https://notes.independentvariable.online",
  },
];
