import React from "react";
import ReactGa from "react-ga";
import mixpanel from "mixpanel-browser";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Project from "./components/Project";
import Skill from "./components/Skill";
import Contact from "./components/Contact";
// import Ip from 'ip'
import Experience from "./components/Experience";
import ClientProjects from "./components/ClientProjects";

const App = () => {
  React.useEffect(() => {
    ReactGa.initialize("G-7ERFVX2Z3Q");
    ReactGa.pageview("/");
    mixpanel.init("77c05b18a226f87a4584823d7f5df8a3");
    mixpanel.track("user visit");
  }, []);
  return (
    <div className="container my-10 mx-auto max-w-screen-xl bg-black">
      <Header />
      <main>
        <Hero />
        <Experience />
        <ClientProjects />
        <Project />
        <Skill />
        <Contact />
      </main>
    </div>
  );
};

export default App;
